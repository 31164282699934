<template>
  <div id="app">
    <header>
      <nav class="menu-principal">
        <b-container>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="text-left">
              <b-img
                src="img/ouvidoria-logo-colorida.png"
                fluid
                alt="Logomarca da Ouvidoria colorida"
                @click="Deslizar('inicio')"
              ></b-img>
              <b-button
                v-b-toggle.sidebar-menu
                v-if="$mq === 'sm' || $mq === 'xs' || $mq === 'md'"
                class="menu-mobile right"
                variant="light"
              >
                <b-icon-list></b-icon-list>
              </b-button>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="8"
              lg="8"
              xl="8"
              v-if="$mq !== 'sm' && $mq !== 'xs' && $mq !== 'md'"
            >
              <ul class="menu-topo">
                <li @click="Deslizar('inicio')">Início</li>
                <li @click="Deslizar('quemsomos')">Quem Somos</li>
                <li @click="Deslizar('estrutura')">Estrutura</li>
                <!-- <li @click="Deslizar('documentos')">Documentos</li> -->
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </nav>
    </header>
    <div>
      <section id="inicio">
        <b-container ref="inicio">
          <b-row
            class="secao"
            :class="{
              conheca: $mq === 'lg' || $mq === 'xl',
              'mt-4': $mq !== 'lg' && $mq !== 'xl',
            }"
          >
            <b-row>
              <b-col cols="12" sm="12" md="5" lg="5" xl="5">
                <h3 class="txt-verde pt-5 mt-5">
                  Conheça a Ouvidoria externa da DPE/PE
                </h3>
                <p class="text-left">
                  A Ouvidoria Geral Externa é um órgão auxiliar da Defensoria
                  Pública do Estado, de promoção da qualidade dos serviços
                  prestados pela Instituição.
                </p>
                <b-button
                  class="bt-verde mb-5 bt-conheca"
                  @click="Deslizar('quemsomos')"
                  >Conheça</b-button
                >
              </b-col>
              <b-col cols="12" sm="12" md="7" lg="7" xl="7">
                <b-img
                  src="img/siouv-logo.png"
                  fluid
                  alt="Logomarca da Souv"
                  @click="Deslizar('inicio')"
                ></b-img>
                <b-button
                  v-b-toggle.sidebar-menu
                  v-if="$mq === 'sm' || $mq === 'xs' || $mq === 'md'"
                  class="menu-mobile right"
                  variant="light"
                >
                  <b-icon-list></b-icon-list>
                </b-button>
                <p>
                  Utilize o botão abaixo para se cadastrar ou caso já possua
                  cadastro utilize o botão entrar, para consultar sua
                  manifestação.
                </p>
                <b-button
                  class="bt-fale p-3"
                  :class="{
                    'w-100 mb-2': $mq === 'xs' || $mq === 'sm',
                    'w-25 m-2': $mq !== 'xs' && $mq !== 'sm',
                  }"
                  @click="Identificar"
                >
                  Entrar
                  <b-icon-box-arrow-in-right class="ml-2" />
                </b-button>
                <b-button
                  class="bt-verde p-3"
                  :class="{
                    'w-100 mb-2': $mq === 'xs' || $mq === 'sm',
                    'w-25 m-2': $mq !== 'xs' && $mq !== 'sm',
                  }"
                  @click="Cadastrar"
                >
                  Cadastrar
                  <b-icon-person-plus-fill class="ml-2" />
                </b-button>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                md="7"
                lg="7"
                xl="7"
                v-if="$mq !== 'sm' && $mq !== 'xs' && $mq !== 'md'"
              >
              </b-col>
            </b-row>
          </b-row>
        </b-container>
      </section>
      <b-row class="p-0 m-0 secao manifestacoes">
        <section id="manifestacoes">
          <b-container ref="manifestacoes">
            <h3 class="txt-branco p-3">
              Qual tipo de manifestação você deseja fazer?
            </h3>
            <b-button
              class="manifestacao p-4"
              :class="{
                'w-25 m-2': $mq !== 'xs' && $mq !== 'sm',
                'w-100 m-1': $mq === 'xs' || $mq === 'sm',
              }"
              v-for="(opcao, index) in opcoes"
              :key="index"
              v-b-popover.click.blur="{
                variant: 'info',
                content: opcao.texto,
                placement: 'bottom',
                animation: true,
                html: true,
              }"
              v-b-popover.hover.blur="{
                variant: 'info',
                content: opcao.texto,
                placement: 'bottom',
                animation: true,
                html: true,
              }"
              :title="opcao.titulo"
            >
              {{ opcao.titulo }}
            </b-button>
          </b-container>
        </section>
      </b-row>
      <b-row class="p-0 m-0 secao">
        <!-- <section id="noticias">
          <b-container>
            <h3 class="txt-verde p-3">Notícias</h3>
            <b-row v-if="this.carregando_noticias">
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                xl="4"
                class="noticia-loader mb-1"
                v-for="i in 9"
                :key="i"
              >
                <small class="mb-2">
                  <vue-skeleton-loader
                    id="ld-rect-0"
                    :width="93"
                    :height="25"
                  />
                </small>
                <a target="_blank">
                  <h4>
                    <vue-skeleton-loader
                      id="ld-rect-1"
                      :width="100"
                      :height="20"
                    />
                    <vue-skeleton-loader
                      id="ld-rect-2"
                      :width="100"
                      :height="20"
                    />
                  </h4>
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                xl="4"
                class="noticia mb-1"
                v-for="noticia in noticias"
                :key="noticia.intnoticiaid"
              >
                <small class="mb-2">
                  <b-icon-calendar /> {{ noticia.data }}
                </small>
                <a
                  target="_blank"
                  :href="
                    UrlNoticia + noticia.intnoticiaid
                  "
                >
                  <h4>
                    {{ noticia.strtitulo }}
                  </h4>
                </a>
              </b-col>
            </b-row>
          </b-container>
        </section> -->
      </b-row>
      <b-row class="p-0 m-0 secao bg-verde">
        <section id="quemsomos">
          <b-container ref="quemsomos">
            <h3 class="txt-verde p-3">A Ouvidoria Externa</h3>
            <p class="text-justify p-2">
              A Ouvidoria Geral Externa é um órgão auxiliar da Defensoria
              Pública do Estado, de promoção da qualidade dos serviços prestados
              pela Instituição. Foi criada em 2012, em consonância com as
              determinações da Lei Complementar 80/94, com alterações promovidas
              pela Lei Complementar 132/09, regulamentada, no âmbito interno,
              pelo Conselho Superior da Defensoria Pública, por meio da
              Resolução nº 001/2012-CSDP/DPE/PE.
            </p>
            <b-row>
              <b-col cols="12" sm="12" md="4" lg="4" xl="4">
                <p class="txt-azul p-2">Missão</p>
                <p class="text-justify p-2">
                  Promover a realização da democracia e a efetividade dos
                  direitos humanos, por meio da mediação de conflitos e do
                  reconhecimento do outro como sujeito de direitos.
                </p>
              </b-col>
              <b-col cols="12" sm="12" md="4" lg="4" xl="4">
                <p class="txt-azul p-2">Função</p>
                <p class="text-justify p-2">
                  Ouvir e compreender as diferentes formas de manifestação dos
                  cidadãos; Reconhecer os cidadãos, sem qualquer distinção, como
                  sujeitos de direitos; Qualificar as expectativas de forma
                  adequada, caracterizando situações e identificando os seus
                  contextos, para que o Estado possa decodificá-las como
                  oportunidades de melhoria; Responder aos cidadãos; Demonstrar
                  os resultados produzidos, avaliando a efetividade das
                  respostas oferecidas e elaborando relatórios gerenciais
                  capazes de subsidiar a gestão pública.
                </p>
              </b-col>
              <b-col cols="12" sm="12" md="4" lg="4" xl="4">
                <p class="txt-azul p-2">Quem deve procurar a Ouvidoria?</p>
                <p class="text-justify p-2">
                  Todas as pessoas e segmentos organizados que relacionem com as
                  ações da Defensoria Pública do Estado de Pernambuco:
                  Servidores(as), defensores(as) públicos(as), estagiários(as),
                  assistidos(as) e potenciais assistidos(as) da instituição.
                  Representações da sociedade civil organizada, órgãos e
                  unidades de outros entes públicos podem e devem procurar a
                  Ouvidoria da DPE/PE.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </section>
      </b-row>
      <b-row class="p-0 m-0 secao">
        <section id="estrutura">
          <b-container ref="estrutura">
            <h3 class="txt-verde p-3">Estrutura</h3>
            <b-row>
              <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                <b-img
                  src="https://www.defensoria.pe.def.br/wp-content/uploads/2023/05/DSC1547-575x1024.jpg"
                  fluid
                  alt="Foto de Fabíola Diniz Araújo de Jesus ouvidora geral"
                  class="foto-ouvidora mb-5"
                ></b-img>
              </b-col>
              <b-col cols="12" sm="12" md="8" lg="8" xl="8">
                <p
                  class="text-justify"
                  :class="{ ' mt-3': $mq !== 'xs' && $mq !== 'sm' }"
                >
                 A Ouvidoria Externa da Defensoria Pública do Estado de Pernambuco desempenha um papel fundamental como um elo de comunicação entre os cidadãos e a instituição. Sua missão é facilitar o acesso à informação relacionada à atuação institucional, bem como aos membros e servidores da Defensoria Pública de Pernambuco (DPPE).
                 <br />
                 <br />
                 Este órgão desempenha um papel estratégico na busca pela melhoria contínua dos serviços oferecidos pela DPPE. Para alcançar esse objetivo, a Ouvidoria Externa está prontamente disponível para receber uma variedade de manifestações da comunidade, incluindo sugestões construtivas, críticas construtivas, elogios e reclamações. Sua função é proporcionar um espaço seguro e imparcial para que qualquer pessoa, independente de sua origem ou circunstâncias, possa expressar suas preocupações, opiniões e experiências com a Defensoria Pública.
                 <br />
                 <br />
                 A acessibilidade e a abertura da Ouvidoria são princípios fundamentais. Ela acolhe todas as manifestações com a devida atenção, tratando cada uma com a seriedade e respeito que merecem. Ao fazer isso, a Ouvidoria Externa da Defensoria de Pernambuco desempenha um papel fundamental na promoção da transparência, na identificação de áreas de aprimoramento e na garantia de que a instituição esteja constantemente evoluindo para melhor servir à comunidade.
                 <br />
                 <br />
                </p>
                
              </b-col>
            </b-row>
                  <ul class="pb-2 text-center" id="integrantes">
                    <li>Karina Maria Da Silva Pereira - Assessora</li> 
                  </ul>
          </b-container>
        </section>
      </b-row>
      <b-row class="p-0 m-0 secao bg-verde">
        <section id="equipe">
          <!-- <b-container ref="equipe">
            <h3 class="txt-verde p-3">Equipe</h3>
            <ul class="pb-2" id="integrantes">
              <li>Karina Maria Da Silva Pereira - Assessora</li> 
            </ul>
          </b-container> -->
        </section>
      </b-row>
      <!-- <b-row class="p-0 m-0 secao">
        <section id="gestoes">
          <b-container ref="gestoes">
            <h3 class="txt-verde p-3">Gestões Anteriores</h3>
            <b-row class="mb-4">
              <b-col cols="2"> </b-col>
              <b-col cols="8">
                <p>
                  <b>Marcia Anunciação Maia Pereira</b><br /><i
                    class="fa fa-calendar"
                    aria-hidden="true"
                  ></i>
                  2018 - 2020
                </p>
                <p>
                  <b>Rosicleia Machado Barbosa Costa</b><br /><i
                    class="fa fa-calendar"
                    aria-hidden="true"
                  ></i>
                  2014 - 2018
                </p>
                <p>
                  <b>Mari-Silva Maia da Silva</b><br /><i
                    class="fa fa-calendar"
                    aria-hidden="true"
                  ></i>
                  2012 - 2014
                </p>
              </b-col>
              <b-col cols="2"> </b-col>
            </b-row>
          </b-container>
        </section>
      </b-row> -->
      <!-- <b-row class="p-0 m-0 secao bg-verde">
        <section id="documentos">
          <b-container ref="documentos">
            <h3 class="txt-verde p-3">Documentos da Ouvidoria</h3>
            <b-row class="mb-4">
              <b-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                xl="4"
                v-for="documento in documentos"
                :key="documento.idconteudosite"
              >
                <a
                  :href="
                    urlDoc + documento.strarquivo
                  "
                  target="_blank"
                >
                  <b-button class="w-100 mb-3 bg-azul p-4 btn-documento">
                    <b-row>
                      <b-col cols="2" sm="2" md="2" lg="2" xl="2">
                        <b-icon-download
                          class="icone-download"
                          :class="{
                            'mt-3': $mq === 'xs' || $mq === 'sm',
                            'mt-1': $mq !== 'xs' && $mq !== 'sm',
                          }"
                        />
                      </b-col>
                      <b-col
                        cols="10"
                        sm="10"
                        md="10"
                        lg="10"
                        xl="10"
                        class="text-left"
                      >
                        {{ documento.strnomeconteudo }}
                      </b-col>
                    </b-row>
                  </b-button>
                </a>
              </b-col>
            </b-row>
            <b-row class="mb-4" v-if="carregando_documentos">
              <b-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                xl="4"
                v-for="i in 6"
                :key="i"
              >
                <a target="_blank">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      class="text-left"
                    >
                      <vue-skeleton-loader
                        id="ld-rect-3"
                        :width="100"
                        :height="91"
                      />
                    </b-col>
                  </b-row>
                </a>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col
                @click="pegarDocumentos"
                cols="12 mais-documentos"
                v-if="mais_documentos && !carregando_documentos"
              >
                <small>Carregar mais...</small>
              </b-col>
            </b-row>
          </b-container>
        </section>
      </b-row> -->
    </div>
    <footer class="rodape">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12" md="4" lg="4" xl="4">
            <!-- <b-img
              src="img/ouvidoria-logo-colorida.png"
              fluid
              alt="Logomarca da Ouvidoria na cor branca"
              class="mb-3"
            ></b-img> -->
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="endereco">
            <div class="bt-whatsapp text-white p-3 m-2">
              <b-row>
                <!-- <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-row>
                    <b-col cols="2" sm="2" md="2" lg="1" xl="1">
                      <b-icon-geo-alt-fill class="geo" />
                    </b-col>
                    <b-col cols="10" sm="10" md="10" lg="10" xl="10">
                      <p>
                        Av. Conde da Boa Vista, n°1450, Empresarial José Maria Matos
                      </p>
                    </b-col>
                  </b-row>
                </b-col> -->
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-row>                    
                    <b-col cols="2" sm="2" md="3" lg="2" xl="2">
                      <b-icon-clock-fill class="geo" />
                    </b-col>
                    <b-col cols="10" sm="10" md="9" lg="10" xl="10">
                      <p>
                        Horário de atendimento:<br />Das 8h às 16h*
                      </p>
                    </b-col>                    

                    <b-col cols="2" sm="2" md="3" lg="2" xl="2">
                      <b-img
                        class="icone"
                        src="img/envelope.svg"
                        alt="Icone Envelope"
                      ></b-img>
                    </b-col>
                    <b-col cols="10" sm="10" md="9" lg="10" xl="10">
                      <p>
                        E-mail<br /><a href="mailto:ouvidoriaexterna@defensoria.pe.gov.br"
                          >ouvidoriaexterna@defensoria.pe.gov.br</a
                        >
                      </p>
                    </b-col>

                    <b-col cols="2" sm="2" md="3" lg="2" xl="2">
                      <b-icon-geo-alt-fill class="geo" />
                    </b-col>
                    <b-col cols="10" sm="10" md="10" lg="10" xl="10">
                      <p>
                        Av. Conde da Boa Vista, n°1450, Empresarial José Maria Matos
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-row>
                    <b-col cols="2" sm="2" md="3" lg="2" xl="2">
                      <b-img
                        class="icone"
                        src="img/headset.svg"
                        alt="Icone Headset"
                      ></b-img>
                    </b-col>
                    <b-col cols="10" sm="10" md="9" lg="10" xl="10">
                      <p>
                        Telefones<br /><a href="tel:+559832215404"
                          >(98) 3221-5404</a
                        >
                        / <a href="tel:+559832315819">32315819</a>
                      </p>
                    </b-col>
                    <b-col cols="2" sm="2" md="3" lg="2" xl="2">
                      <b-img
                        class="icone"
                        src="img/whatsapp.svg"
                        alt="Icone do Whatsapp"
                      ></b-img>
                    </b-col>
                    <b-col cols="10" sm="10" md="9" lg="9" xl="9">
                      <p>
                        Whatsapp<br /><a
                          href="https://api.whatsapp.com/send?phone=+5598992420257&text=Olá gostaria de informações"
                          target="_blank"
                          >(98) 99242 - 0257</a
                        >
                      </p>
                    </b-col>
                  </b-row>
                </b-col> -->
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="copyright">
        © Copyright | {{ ano }} | Ouvidoria externa da Defensoria Pública do
        Estado de Pernambuco
      </div>
    </footer>
    <b-sidebar id="sidebar-menu" title="Menu" backdrop no-header shadow>
      <div class="px-3 py-2">
        <b-img
          src="img/ouvidoria-logo-colorida.png"
          fluid
          alt="Logomarca da Ouvidoria colorida"
          class="mb-4 mt-3"
        ></b-img>
        <ul class="sidenav-mobile m-0 p-2">
          <li class="sem-linha" @click="DeslizarMovel('inicio')">Início</li>
          <li @click="DeslizarMovel('quemsomos')">Quem Somos</li>
          <li @click="DeslizarMovel('estrutura')">Estrutura</li>
          <!-- <li @click="DeslizarMovel('documentos')">Documentos</li> -->
        </ul>
      </div>
    </b-sidebar>
    <ModalIdentificacao />
    <ModalRedefinirSenha :token="this.token" />
    <ModalCadastrar />
  </div>
</template>

<script>
import ModalIdentificacao from "./components/ModalIdentificacao.vue";
import ModalRedefinirSenha from "./components/ModalRedefinirSenha.vue";
import ModalCadastrar from "./components/ModalCadastrar.vue";
// import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  name: "App",
  components: {
    ModalIdentificacao,
    ModalRedefinirSenha,
    ModalCadastrar
    // VueSkeletonLoader,
  },
  data() {
    return {
      carregando_noticias: true,
      carregando_documentos: true,
      mais_documentos: true,
      pagina_documentos: 0,
      modalIdentificar: 1000,
      modalCadastrar: 7000,
      modalRedefinir: 9000,
      ano: new Date().getFullYear(),
      api: process.env.VUE_APP_URL_API,
      urlDoc: process.env.VUE_APP_URL_DOCUMENTOS,
      UrlNoticia: process.env.VUE_APP_URL_NOTICIAS,
      noticias: [],
      documentos: [],
      token: null,
      opcoes: [
        {
          titulo: "Reclamações",
          texto:
            "Manifestação de insatisfação com o serviço público prestado pela Defensoria Pública, incluindo ação e omissão da sua administração e/ou dos seus defensores públicos, servidores e estagiários. Também incluem-se nessa categoria as críticas e opiniões desfavoráveis sobre os serviços e atos da Defensoria Pública do Estado e os seus respectivos membros.<br><a href='#none' onClick='Identificar()'>Manifestar-se</a>",
        },
        {
          titulo: "Sugestões",
          texto:
            "Apresentação de ideia ou formulação de proposta de aprimoramento de políticas e serviços prestados pela Defensoria Pública.<br><a href='#none' onClick='Identificar()'>Manifestar-se</a>",
        },
        {
          titulo: "Denúncias",
          texto:
            "Comunicação de prática de irregularidade ou ato ilícito cuja solução dependa da atuação de órgão de controle interno ou externo. Devem envolver irregularidades ocorridas na Defensoria Pública, como crimes, infrações disciplinares, atos improbidade administrativa, de corrupção, exercício negligente ou abusivo dos cargos, emprego e funções.<br><a href='#none' onClick='Identificar()'>Manifestar-se</a>",
        },
        {
          titulo: "Elogios",
          texto:
            "Demonstração de apreço, reconhecimento ou satisfação sobre o serviço oferecido e/ou recebido ou relativo às pessoas que participaram do serviço/atendimento.<br><a href='#none' onClick='Identificar()'>Manifestar-se</a>",
        },
        {
          titulo: "Solicitações",
          texto:
            "Requerimentos que solicitam uma providência prática, pedidos de informações, esclarecimentos, orientações e simplificações.<br><a href='#none' onClick='Identificar()'>Manifestar-se</a>",
        },
        {
          titulo: "Comunicação anônima",
          texto:
            "São informações de origem anônima que comunicam irregularidades com indícios mínimos de relevância, autoria e materialidade, nos termos da Lei 13.608/2018, as quais, por não serem identificadas, não ensejam obrigação de resposta conclusiva.<br><a href='#none' onClick='Identificar()'>Manifestar-se</a>",
        },
      ],
    };
  },
  methods: {
    alertaSucesso(mensagem) {
      this.$swal("Confirmação", mensagem, "success");
    },
    alertaErro(mensagem) {
      this.$swal("Erro", mensagem, "error");
      //this.Toast.fire(mensagem, "", "error");
    },
    Identificar() {
      this.modalIdentificar++;
      this.$root.$emit("bv::show::modal", "modal-login");
    },
    Cadastrar() {
      this.modalCadastrar++;
      this.$root.$emit("bv::show::modal", "modal-cadastrar");
    },
    Redefinir() {
      this.modalRedefinir++;
      this.$root.$emit("bv::show::modal", "modal-redefinir");
    },
    Deslizar(documento) {
      this.$smoothScroll({
        scrollTo: document.getElementById(documento),
        hash: "#" + documento,
      });
    },
    DeslizarMovel(documento) {
      let el = "";
      switch (documento) {
        case "quemsomos":
          el = this.$refs.quemsomos;
          break;
        case "estrutura":
          el = this.$refs.estrutura;
          break;
        case "documentos":
          el = this.$refs.documentos;
          break;
        case "manifestese":
          el = this.$refs.manifestese;
          break;
        default:
          el = this.$refs.inicio;
      }
      var top = el.offsetTop;
      window.scrollTo(0, top - 90);
      if (this.$mq === "xs" || this.$mq === "sm")
        this.$root.$emit("bv::toggle::collapse", "sidebar-menu");
    },
    async pegarNoticias() {
      await this.axios.get(this.api + "noticias").then((response) => {
        this.carregando_noticias = false;
        this.noticias = response.data;
      });
    },
    async pegarDocumentos() {
      this.carregando_documentos = true;

      await this.axios
        .get(this.api + "documentos?page=" + this.pagina_documentos)
        .then((response) => {
          this.pagina_documentos++;
          this.carregando_documentos = false;
          if (response.data.data.length < 6) this.mais_documentos = false;
          this.documentos = this.documentos.concat(response.data.data);
        });
    },
    TratarUrl() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      if (params.get("ativacao") !== null) {
        this.HabilitarConta(params.get("ativacao"));
      }
      if (params.get("recuperar") !== null) {
        this.token = params.get("recuperar");
        this.Redefinir();
      }
      if (params.get("login") !== null) {
        this.Identificar();
      }
    },
    HabilitarConta(token) {
      this.$swal.fire({
        title: "Ativação de conta",
        html: "Estamos ativando a sua conta, aguarde...",
        didOpen: () => {
          this.$swal.showLoading();
          this.axios
            .get(this.api + "usuario/habilitar/" + token)
            .then((response) => {
              if (response.data) {
                this.$swal
                  .fire({
                    title: "Tudo certo",
                    text: response.data.mensagem,
                    icon: "success",
                    confirmButtonColor: "green",
                  })
                  .then(() => {
                    this.Identificar();
                  });
              }
            })
            .catch(() => {
              this.$swal.fire({
                title: "Erro",
                text: "O token de ativação é inválido!",
                icon: "error",
                showConfirmButton: false,
                showDenyButton: true,
                denyButtonText: "Ok",
              });
            });
        },
      });
    },
    RecuperarSenha() {},
  },
  mounted: function () {
    this.pegarNoticias();
    this.pegarDocumentos();
    this.TratarUrl();
  },
  created() {
    window.Identificar = this.Identificar.bind(this);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,700&display=swap");
html,
body {
  padding: 0;
  margin: 0;
}
#app {
  text-align: center;
  font-family: "Roboto", sans-serif;
}
h3 {
  font-weight: 700;
  font-style: italic;
}
.txt-verde {
  color: #00843a;
}
.txt-branco {
  color: #ffffff;
}
header {
  background-color: white !important;
}
nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 6rem;
  z-index: 10;
  background: linear-gradient(180deg, #ffffff 0%, #cecece 100%);
  border-bottom: 10px solid #00843a;
}
nav.menu-principal ul {
  display: flex;
  font-size: 16px;
  list-style: none;
  cursor: pointer;
  user-select: none;
}
nav.menu-principal ul li {
  width: 100%;
  height: 6rem;
  padding-top: 1.8rem;
}
footer.rodape {
  padding-top: 3rem;
  padding-bottom: 4rem;
  position: relative;
  background-color: #00843a;
  color: white;
  width: 100%;
  min-height: 10rem;
}
.copyright {
  width: 100%;
  text-align: center;
  background-color: #035728;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  position: absolute;
  bottom: 0;
  font-size: 12px;
}
.endereco {
  text-align: left;
  font-size: 14px;
}
.menu-rodape {
  text-align: left;
  list-style: none;
  cursor: pointer;
}
.menu-topo {
  font-size: 16px;
}
#btn-manifeste {
  background-color: #00843a;
  color: white;
  transition: font-size 0.2s linear;
}
#btn-manifeste:hover {
  background-color: #00843a;
  color: white;
  font-size: 18px;
}
nav ul li:hover {
  background-color: #00843a;
  color: white;
  transition: background-color 0.5s linear;
}
.bg-verde {
  background-color: #dcece3;
}
.manifestacoes {
  background: rgba(0, 0, 0, 0.5);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 40vh;
}
.bt-verde {
  background: #00843a !important;
}
.bt-fale {
  background: #235e85 !important;
  border-radius: 5px;
}
.bt-whatsapp {
  display: inline-block;
  background: #00843a;
  border-radius: 5px;
}
.text-left {
  text-align: justify;
}
.bt-conheca {
  float: left;
}
.menu-mobile {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 1.5rem;
}
.sidenav-mobile {
  list-style: none !important;
  text-align: left;
}
.sidenav-mobile li {
  border-top: 1px dotted rgb(156, 156, 156);
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 0.5rem;
  padding-right: 0;
  margin: 0;
  cursor: pointer;
}
.sem-linha {
  border: none !important;
}
#sidebar-menu {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 40%,
    #c5c5c5 100%
  ) !important;
}
.conheca {
  margin-top: 7rem !important;
}
.noticia small {
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  background-color: #235e85;
  color: white;
  padding: 0.2rem 0.3rem;
  border-radius: 0.3rem;
  display: block;
  width: fit-content;
  text-align: left !important;
}
.noticia a {
  text-decoration: none;
  color: black;
}
.noticia h4 {
  font-size: 1rem;
  text-align: justify;
  font-weight: lighter;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem dotted #cccccc;
  font-weight: 300;
  min-height: 80px;
}
.noticia {
  padding: 0.5rem;
}
.geo {
  font-size: 2rem;
}
.icone {
  height: 2rem !important;
}
.foto-ouvidora {
  text-align: center !important;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.53);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.53);
  max-height: 20rem;
  border: 10px solid white;
}
.text-justify {
  text-align: justify;
}
.txt-azul {
  color: #235e85 !important;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: left;
  line-height: normal;
  width: 100%;
  border-bottom: 1px dashed #235e85;
}
.manifestacao {
  display: inline-block;
  background-color: #235e85 !important;
  color: white;
  cursor: pointer;
}
.bg-azul {
  background-color: white !important;
  border: 1px solid #235e85 !important;
  color: #235e85 !important;
  font-weight: bold !important;
}
.bg-azul:hover,
.bg-azul:focus {
  background-color: #235e85 !important;
  border: 1px solid #235e85 !important;
  color: white !important;
  font-weight: bold !important;
}
.icone-download {
  margin-right: 0.5rem !important;
  font-size: 2rem !important;
}
footer.rodape a {
  color: white !important;
  text-decoration: none !important;
}
button.close {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  color: white !important;
  font-size: 1.5rem !important;
}
.swal2-confirm.swal2-styled {
  background-color: #0a0 !important;
}
.swal2-cancel.swal2-styled {
  background-color: #aaa !important;
}
.btn-documento {
  height: 80%;
}
.btn-documento div {
  align-items: center;
}
#ld-rect-0 {
  color: #aaa !important;
  border: 0 !important;
}
#ld-rect-1,
#ld-rect-2,
#ld-rect-3 {
  margin-top: 5px;
  width: 100% !important;
}
#ld-rect-3 {
  margin-bottom: 15px;
}
.mais-documentos {
  color: #235e85;
  font-weight: bold;
  cursor: pointer;
}
#integrantes {
  list-style-type: none;
}
</style>
